import {
  React,
  SignUpStyles,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Box,
  TextField,
  LoginStyles,
  useState,
  Input,
  useEffect,
  axiosGet,
} from '../../collections/Imports';
import useAPIError from './../../hooks/useAPIError';
import { Helmet } from 'react-helmet';
import { CodeSharp } from '@material-ui/icons';

const SignupRoles = props => {
  const classes = SignUpStyles();
  const { addError } = useAPIError();
  const classes1 = LoginStyles();
  const [selected_user_role_id, setSelectedUserRoleId] = useState('');
  const [selected_user_role, setSelectedUserRole] = useState('');
  const [originalUserRoles, setOriginalUserRoles] = useState([])
  const [userRoles, setUserRoles] = useState([]);
  const [cinNo, setCinNo] = useState('');
  const [panNo, setPanNo] = useState('');
  const [file, setFile] = useState('');
  const [ip_registration_number, setIPRegistartionNumber] = useState('')
  const initialState = {
    name: '',
    email: '',
    phone: '',
    password: '',
    cinNo: '',
    panNo: '',
    file: '',
  };
  const [values] = useState({ initialState });

  const changeSelectOptionHandler = event => {
    setSelectedUserRoleId(event.target.value);
    let index = userRoles.findIndex(userRole => {
      return userRole._id.toString() === event.target.value.toString();
    });
    if (index > -1) {
      setSelectedUserRole(userRoles[index].user_role);
    }
  };

  const handleChange = e => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    axiosGet('api/user-roles/all')
      .then(result => {
        let { userRoles } = result;
        userRoles = userRoles.filter((u) => u.user_role_id == 1 || u.user_role_id == 7)
        setUserRoles(userRoles);
        setOriginalUserRoles(userRoles)
      })
      .catch(err => {
        addError(`LOAD_DATA_ERROR: ${err}`);
      });
  }, []);

  useEffect(
    () => {
      const values = {
        cinNo,
        panNo,
        file,
        selected_user_role_id,
      };
      props.setData(values);
      props.setCinNo(cinNo);
      props.setPanNo(panNo);
      props.setIPRegistartionNumber(ip_registration_number)
    },
    [cinNo, panNo, ip_registration_number, file, selected_user_role_id]
  );
  const CinNo = () => {
    return (
      <Box>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="title"
            content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
          />
          <meta
            name="description"
            content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
          />
        </Helmet>
        <FormControl margin="normal" required fullWidth>
          <Typography>CIN No.</Typography>
          <TextField
            required={true}
            className={classes1.inputfield}
            placeholder="Enter Your CIN"
            variant="outlined"
            name="cinNo"
            id="cinNo"
            type="text"
            onChange={e => setCinNo(e.target.value)}
            value={cinNo}
            inputProps={{ minLength: 8, maxLength: 21 }}
          />
        </FormControl>
        <Input
          className={classes.fileInput}
          required={true}
          name="cinfile"
          id="file"
          type="file"
          onChange={handleChange}
          inputProps={{ accept: 'application/pdf,image/*' }}
        />
        {(selected_user_role === 'Company' || selected_user_role_id === '609e210d48e38de36b0a213b')
          ? <Typography varient="h6" gutterBottom
            style={{ paddingLeft: '8px' }}
          >
            Upload your Certificate of Incorporation (CoI)
          </Typography>
          : null}
      </Box>
    );
  };

  const ipRegistrationNumber = () => {
    return (
      <Box>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="title"
            content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
          />
          <meta
            name="description"
            content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
          />
        </Helmet>
        <FormControl margin="normal" required fullWidth>
          <Typography>IP Registration No.</Typography>
          <TextField
            required={true}
            className={classes1.inputfield}
            placeholder="Enter Your IP Registartion No"
            variant="outlined"
            name="ip_registration_number"
            id="ip_registration_number"
            type="text"
            onChange={e => setIPRegistartionNumber(e.target.value)}
            value={ip_registration_number}
          // inputProps={{ minLength: 8, maxLength: 21 }}
          />
        </FormControl>
      </Box>
    );
  };

  const PanCard = () => {
    return (
      <Box>
        <FormControl margin="normal" required fullWidth>
          <Typography>PAN No.</Typography>
          <TextField
            required={true}
            className={classes1.inputfield}
            placeholder="Enter Your PAN"
            variant="outlined"
            id="pan"
            name="pan"
            type="text"
            onChange={e => setPanNo(e.target.value)}
            value={values.panNo}
            inputProps={{ maxLength: 10 }}
          />
        </FormControl>
        <Input
          required={true}
          className={classes.fileInput}
          accept="image/*"
          name="panfile"
          type="file"
          id="file"
          onChange={handleChange}
          inputProps={{ accept: 'application/pdf,image/*' }}
        />
        {selected_user_role === 'Scrutinizer'
          ? <Typography varient="h6" gutterBottom>
            Please upload your PAN card
          </Typography>
          : null}
      </Box>
    );
  };

  useEffect(() => {
    if (props.isIndiaFlag) {
      setUserRoles(originalUserRoles)
    } else {
      let index = userRoles.findIndex(userRole => {
        return userRole.user_role_id == 7;
      });
      if (index > -1) {
        setSelectedUserRole(userRoles[index].user_role);
        setSelectedUserRoleId(userRoles[index]._id)
      }
    }
  }, [props.isIndiaFlag])

  useEffect(() => {
    let index = userRoles.findIndex(userRole => {
      return userRole.user_role_id == 7;
    });
    if (index > -1) {
      setSelectedUserRole(userRoles[index].user_role);
      setSelectedUserRoleId(userRoles[index]._id)
    }
  }, [])

  return props.isIndiaFlag && (
    <Box style={{ marginTop: '1rem' }}>
      <Typography>Poll Reason</Typography>
      <FormControl variant="outlined" className={classes.selectcontrol}>
        <Select
          onChange={e => changeSelectOptionHandler(e)}
          value={selected_user_role_id}
          required={true}
        >
          {userRoles &&
            userRoles.map(type => {
              return (
                <MenuItem
                  key={type._id}
                  value={type._id}
                  id={type.user_role_id}
                >
                  {type.user_role}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Box>
        {(selected_user_role === 'Company' || selected_user_role_id === '609e210d48e38de36b0a213b') ? ipRegistrationNumber() : null}
        {/* {(selected_user_role === 'Company' || selected_user_role_id === '609e210d48e38de36b0a213b') ? CinNo() : null} */}
        {selected_user_role === 'Scrutinizer' ? PanCard() : null}
      </Box>
    </Box>
  );
};

export default SignupRoles;
