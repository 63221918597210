const PencilSquare = () => {
  return (
    <svg
      width="26"
      height="14"
      viewBox="0 0 26 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08597 0.71146C1.17306 0.624154 1.27651 0.554886 1.39041 0.507624C1.50431 0.460362 1.62641 0.436035 1.74972 0.436035C1.87304 0.436035 1.99514 0.460362 2.10904 0.507624C2.22293 0.554886 2.32639 0.624154 2.41347 0.71146L12.9997 11.2996L23.586 0.71146C23.6731 0.624296 23.7766 0.555152 23.8905 0.507979C24.0044 0.460806 24.1264 0.436526 24.2497 0.436526C24.373 0.436526 24.4951 0.460806 24.6089 0.507979C24.7228 0.555152 24.8263 0.624296 24.9135 0.71146C25.0006 0.798625 25.0698 0.902105 25.117 1.01599C25.1641 1.12988 25.1884 1.25194 25.1884 1.37521C25.1884 1.49848 25.1641 1.62054 25.117 1.73443C25.0698 1.84832 25.0006 1.9518 24.9135 2.03896L13.6635 13.289C13.5764 13.3763 13.4729 13.4455 13.359 13.4928C13.2451 13.5401 13.123 13.5644 12.9997 13.5644C12.8764 13.5644 12.7543 13.5401 12.6404 13.4928C12.5265 13.4455 12.4231 13.3763 12.336 13.289L1.08597 2.03896C0.998667 1.95187 0.929399 1.84842 0.882137 1.73452C0.834875 1.62063 0.810547 1.49852 0.810547 1.37521C0.810547 1.2519 0.834875 1.12979 0.882137 1.0159C0.929399 0.902 0.998667 0.798546 1.08597 0.71146Z"
        fill="#1171FF"
      />
    </svg>
  );
};

export default PencilSquare;
